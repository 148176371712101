import React from 'react';
import DocumentTitle from 'react-document-title';

function Index() {
    return (
        <DocumentTitle title="感谢支持小青账">
            <div className="home-page">
                <div className="home-page-wrapper">
                    <h1>感谢支持小青账</h1>
                    <br/>
                    <p>小青账成立于2021年，为解决个人财务记账需求，帮助个人分析财务状况。</p>
                    <p>如您需要了解更多合作，可根据您的合作意向联系下方名片，共同成长进步~</p>
                    <br/>
                    <h3>联系我们</h3>
                    <br/>
                    <img width="200px" alt="" src="Wechat-gzh.jpeg"/>
                    <br/>
                </div>
            </div>
        </DocumentTitle>
    );
}

export default Index;
