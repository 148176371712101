import React from 'react';
import DocumentTitle from 'react-document-title';

function Index() {
    return (
        <DocumentTitle title="小青账-会员服务协议">
            <div className="home-page">
                <div className="home-page-wrapper">
                    <h1>会员服务协议</h1>
                    <br/>
                    <p>更新日期：2022年03月22日</p>
                    <p>生效日期：2022年03月23日</p>
                    <br/>
                    <p>提示条款</p>
                    <br/>
                    <p>各服务条款前所列索引关键词仅为帮助您理解该条款表达的主旨之用，不影响或限制本协议条款的含义或解释。为维护您自身权益，建议您仔细阅读各条款具体表述。</p>
                    <p>【审慎阅读】您在申请开通VIP会员之前，应当认真阅读本协议。请您务必审慎阅读、充分理解各条款内容，特别是免除或者限制责任的条款、法律适用和争议解决条款。免除或者限制责任的条款将以粗体下划线标识，您应重点阅读。如您对协议有任何疑问，可向小青账客服咨询。</p>
                    <p>【签约动作】当您按照VIP会员开通页面提示阅读并同意本协议且完成全部开通程序后，即表示您已充分阅读、理解并接受本协议的全部内容，并与小青账（定义见下文）达成一致，成为VIP会员。阅读本协议的过程中，如果您不同意本协议或其中任何条款约定，您应立即停止开通程序。</p>
                    <br/>
                    <h3>1、 定义与修改</h3>
                    <p>a) 本协议是VIP会员与"有钳智能科技（郑州）有限公司"（下称“小青账”）之间关于用户成为小青账VIP会员且使用小青账提供的VIP会员服务所订立的协议。本协议描述小青账与VIP会员之间关于软件许可以及服务使用及相关方面的权利义务。“VIP会员”或“您”是指享受小青账提供的VIP会员服务的个人或单一实体。本服务协议构成您（无论个人或者单位）使用小青账所提供的VIP会员服务之先决条件，除非您接受本协议条款，否则您无权使用本协议的相关服务。您的使用行为将视为同意接受本协议各项条款的约束。</p>
                    <p>b) 小青账有权随时对服务条款进行修改，一旦服务条款发生变更和修改，小青账将在相关页面上提示修改的内容；如果VIP会员不同意本协议的修改，可以取消已经获取的服务并停止使用；如果VIP会员继续使用小青账提供的服务，则视为VIP会员已经接受本协议的全部修改。</p>
                    <p>c) 请您审阅并接受或不接受本协议，未成年人应在法定监护人陪同下审阅和履行，未成年人行使和履行本协议项下的权利和义务视为已获得了法定监护人的认可，您在享受小青账VIP会员服务时必须完全、严格遵守本服务协议条款。</p>
                    <br/>
                    <h3>2. 会员服务开通</h3>
                    <p>a) VIP会员服务仅针对交易及使用记录良好的用户开放获取资格，您可通过会员中心查看您是否满足服务开通条件。在您成功开通（包括续费、重新开通）前，您和小青账的选择是双向的，您有权选择是否开通，小青账亦有权选择接受或拒绝您的开通申请。</p>
                    <p>b) 您应确保在VIP会员服务开通及使用过程中，始终具备完全民事权利能力和完全民事行为能力，并确保您具有签订和履行本协议项下权利义务之实际能力。</p>
                    <p>c) VIP会员服务开通前，您需要完成VIP会员的注册或登录。</p>
                    <p>d) 您申请开通VIP会员服务时，需根据页面提示支付相应的会员服务费用后方可完成开通。基于权益调整、市场与业务的发展，小青账可能会调整VIP会员服务开通所需会员服务费用，或向您提供与会员权益相关的增值服务，并可能收取增值服务的费用。会员服务费用调整自公布之日起生效，您于生效前已开通的VIP会员服务不受影响，但该VIP会员服务到期后若需续费开通，则需按照调整后已生效的费用标准支付。增值服务开始收费前小青账将以适当的方式通知您具体标准，您有权选择拒绝收费的增值项目。</p>
                    <p>e) 成为VIP会员后，VIP会员有权利不接受小青账的服务，可申请取消VIP会员服务，但不获得VIP会员服务费的退还。</p>
                    <br/>
                    <h3>3. 会员服务使用</h3>
                    <p>a) 在VIP会员有效期内，VIP会员可优先参加由小青账组织的活动并享有由小青账提供的各项优惠及增值服务。</p>
                    <p>b) VIP会员服务仅限于申请账号自行使用；VIP会员服务期内不能在小青账帐号之间转移，禁止赠与、借用、租用、转让或售卖。否则小青账有权在未经通知的情况下取消转让账户、受让账户的VIP会员服务资格，由此带来的损失由VIP会员自行承担。</p>
                    <p>c) 若VIP会员的行为持续违反本协议或违反国家相关法律法规，或小青账认为VIP会员行为有损小青账或他人的声誉及利益，小青账公司有权取消该VIP会员的VIP会员资格而无须给与任何补偿。</p>
                    <p>d) 任何VIP会员不得使用带有非法、淫秽、污辱或人身攻击的含义污辱或人身攻击的呢称和签名档，一经发现，小青账有权取消其VIP会员资格而无需给与任何补偿和退费。</p>
                    <p>e) VIP会员不得以盗窃、利用系统漏洞等非法途径以及在未获小青账授权的非法销售小青账VIP会员的网站上获取或购买VIP会员服务，否则小青账有权取消VIP会员的服务资格。有此引发的问题由VIP会员自行承担，小青账不负任何责任。</p>
                    <p>f) 被取消VIP会员资格的VIP会员，不能再参加由小青账组织的活动并不可再享有由小青账提供的各项优惠及增值服务，即不再享有VIP会员权利。</p>
                    <br/>
                    <h3>4. 会员服务终止</h3>
                    <p>a) 您购买的VIP会员服务有效期届满后如您未续费或重新开通的，VIP会员服务终止，对应的VIP会员权益失效。</p>
                    <p>b) 您可通过账号注销的方式将VIP会员服务终止，由此会员与绑定的账号都将注销和回收，此操作不可逆，也不会因此退回会员费用。</p>
                    <p>c) 如VIP会员违反或被视为违反本服务条款中的内容，小青账有权在不通知VIP会员的情况下立即终止您已购买的所有服务，以及取消您的小青账VIP会员账户和使用权限，但不退还任何已缴纳的VIP会员服务费用。</p>
                    <p>d) 因发生小青账不可抗拒的事由，如政府行为、不可抗力，导致VIP会员服务无法继续，小青账会以最快的速度通知VIP会员，但不承担由此对VIP会员造成的任何损失并不退还VIP会员服务费。</p>
                    <p>e) 小青账未行使或延迟行使其在本协议项下的权利并不构成对这些权利的放弃，而单一或部分行使其在本协议项下的任何权利并不排斥其任何其它权利的行使。小青账随时有权要求您继续履行义务并承担相应的违约责任。</p>
                    <br/>
                    <h3>5.无担保和责任限制</h3>
                    <p>a) 您理解小青账将尽最大努力确保本服务及其所涉及的技术及信息安全、有效、准确、可靠，但受限于现有技术及小青账有限的服务能力，对于下述原因导致的合同履行障碍、瑕疵、延后或内容变更等情形，导致您直接或间接损失，您理解小青账无法承担责任：
                        因自然灾害、罢工、暴乱、战争、恐怖袭击、政府行为、司法行政命令等不可抗力因素；
                        因电信部门技术调整或故障、通讯网络故障等公共服务因素；
                        用户自身设备的软件、系统、硬件和通信线路出现故障，或用户通过非本协议项下约定的方式使用本服务的；
                        小青账已善意管理，但出现紧急设备/系统的故障/维护、网络信息与数据安全等情况。</p>
                    <p>b) 本服务条款的生效、履行、解释及争议的解决均适用中华人民共和国法律，本服务条款因与中华人民共和国现行法律相抵触而导致部分无效，不影响其他部分的效力。</p>
                    <p>c) 如就本协议内容或其执行发生任何争议，应尽量友好协商解决；协商不成时，则争议各方一致同意将发生的争议提交协商不成时，任何一方均可向郑州人民法院提起诉讼。</p>
                </div>
            </div>
        </DocumentTitle>
    );
}

export default Index;
