import React from 'react';
import DocumentTitle from 'react-document-title';

function Index() {
    return (
        <DocumentTitle title="小青账-用户授权协议">
            <div className="home-page">
                <div className="home-page-wrapper">
                    <h1>《用户授权协议》</h1>
                    <p>（以下简称“本协议”）是有钳智能科技（郑州）有限公司（以下合称“小青账”）与用户（以下简称“您”）所订立的有效合约。</p>
                    <p>【审慎阅读】</p>
                    <p>您即将通过本页面使用小青账平台账户授权登录第三方站点/软件或进行一定数据共享。确认授权前请您仔细阅读本协议的全部内容（特别是以粗体下划线标注的内容）。如您不同意本协议内容，或无法准确理解本协议任何条款的含义，请不要进行授权及后续操作。</p>
                    <p>【签约动作】</p>
                    <p>您通过网络页面确认同意接受本协议，视为您同意接受本协议的全部约定内容。</p>
                    <p>【共享信息】</p>
                    <p>您授权小青账将您小青账平台账户的相关信息和数据传递给第三方，用于第三方为您提供服务。页面提示上会展示具体授权对象、授权字段及内容等，授权字段及内容将通过加密通道传递给第三方。小青账会要求该第三方依法使用您的上述信息。如您不希望向第三方提供上述信息，请勿点击确认授权。</p>
                    <p>【责任承担】</p>
                    <p>您理解，第三方服务由相应第三方提供并就以上服务及数据安全独立承担责任。因该第三方服务或其使用您的信息产生的纠纷，或第三方服务违反相关法律法规，或您在使用第三方服务过程中遭受的损失，请您与第三方协商解决。</p>
                    <p>【安全提示】</p>
                    <p>网络欺诈往往会模仿、仿冒本登录页面的样式制作视觉感相似的页面引诱用户输入账号和密码等信息，小青账在此提醒您防范网络风险，不要向非小青账的站点或任何人士透露您的小青账平台账户的名称、密码等相关信息。</p>
                    <p>【协议变更】</p>
                    <p>您同意，小青账可不时修订本协议，并将协议变更情况在小青账平台的网站/客户端上进行公示，该等变更自公告载明的生效时间开始生效，并成为本协议的一部分。</p>
                    <p>【争议解决】</p>
                    <p>凡因本协议引起的及任何与之有关的争议，首先应由该等争议的相关当事方通过友好协商解决，如果无法友好协商解决的，任何一方均可向被告所在地有管辖权的人民法院提起诉讼。</p>
                    <p>【其他】</p>
                    <p>本协议未尽事宜，参照《隐私权政策》执行；《隐私权政策》与本协议有冲突的，以本协议为准。</p>
                </div>
            </div>
        </DocumentTitle>
    );
}

export default Index;
