import React from 'react';
import DocumentTitle from 'react-document-title';

function Index() {
    return (
        <DocumentTitle title="小青账-帮助">
            <div className="home-page">
                <div className="home-page-wrapper">
                    <h1>帮助</h1>
                    <br/>
                    <p>引言</p>
                    <br/>
                    <p>小青账部分功能处于隐藏状态，需要耐心发现和使用才会有所发觉。</p>
                    <p>希望有能力的同学伙伴来帮助小青账成长，可向小青账官方提供可供其他伙伴参考的文档或者说明。后续活动或者礼物享有优先获取权力。（请联系微信：MercyYao）</p>
                    <br/>
                    <p>根据文档为发现功能，请在我的-设置-关于查看自己安装的版本，对比下面功能出现的版本，如自己安装的版本小于功能出现的版本请尝试更新后在使用。</p>
                    <p><a href="/roadmap">版本规划及发布日期 点这里<span role='img' aria-labelledby="panda1">👈</span></a></p>
                    <br/>
                    <h3><a
                        href="https://note.youdao.com/ynoteshare1/mobile.html?id=9859272b8335355f25a7a65746427545&type=note#/">1.
                        新手引导 点这里<span role='img' aria-labelledby="panda1">👈</span></a></h3>
                    <p>包含简单设置如：主题、密码锁、记账日期更改。分类管理、记账使用说明、资产使用说明等</p>
                    <br/>
                    <h3><a
                        href="https://note.youdao.com/ynoteshare1/mobile.html?id=5def37da692e7e34c1b7db39b6dd4f5b&type=note#/">2.
                        账单报销 点这里<span role='img' aria-labelledby="panda1">👈</span>(1.0.0版本)</a></h3>
                    <p>简单公司报销说明。</p>
                    <br/>
                    <h3><a
                        href="https://note.youdao.com/ynoteshare1/mobile.html?id=16fd42cf895b84bae0a3cad2e47e41d5&type=note#/">3.
                        内部转账 点这里<span role='img' aria-labelledby="panda1">👈</span>(1.0.0版本)</a></h3>
                    <p>包含花呗信用卡还款、银行支付宝转账等</p>
                    <br/>
                    <h3><a
                        href="https://note.youdao.com/ynoteshare1/index.html?id=998a6f8074f177c26b0efea2b26fa485&type=note">4.
                        多账本 点这里<span role='img' aria-labelledby="panda1">👈</span>(1.3.0版本)</a></h3>
                    <p>不同的账单可以归纳到不同的账本里，如旅游的、孩子教育的、考研或者家庭的账单归纳到不同的账本里，以便方便统计。</p>
                    <br/>
                    <h3><a
                        href="https://note.youdao.com/ynoteshare1/index.html?id=d4fdc0852b08057adc311aaced82e6a8&type=note#/">5.
                        预算 点这里<span role='img' aria-labelledby="panda1">👈</span>(1.4.0版本)</a></h3>
                    <p>为每个月设置预算，吃饭、房租、化妆品都可单独设置，以免本月花出过多的钱，让花钱也要有预算。</p>
                </div>
            </div>
        </DocumentTitle>
    );
}

export default Index;
