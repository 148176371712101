import React from 'react';
import DocumentTitle from 'react-document-title';

function Index() {
    return (
        <DocumentTitle title="小青账-隐私政策">
            <div className="home-page">
                <div className="home-page-wrapper">
                    <h1>隐私政策</h1>
                    <br/>
                    <p>更新日期：2023年05月18日</p>
                    <p>生效日期：2023年05月18日</p>
                    <br/>
                    <h3>引言</h3>
                    <br/>
                    <p>小青账（以下简称我们）尊重并保护所有使用服务用户的个人隐私权。您在使用我们的服务时，我们可能会收集和使用您的相关信息。但小青账将以高度的勤勉、审慎义务对待这些信息。除本隐私权政策另有规定外，在未征得您事先许可的情况下，小青账不会将这些信息对外披露或向第三方提供。小青账会不时更新本隐私权政策。
                        您在同意小青账服务使用协议之时，即视为您已经同意本隐私权政策全部内容。本隐私权政策属于小青账服务使用协议不可分割的一部分。</p>
                    <br/>
                    <h3>关于我们</h3>
                    <br/>
                    <p>小青账网站及APP的经营者为<strong>有钳智能科技（郑州）有限公司</strong>及其他关联公司。</p>
                    <p>联系地址：河南省郑州市高新技术产业开发区科学大道53号中原广告产业园7号楼2单元1层R08-596号</p>
                    <p>信息保护负责人：vczr@qq.com</p>
                    <br/>
                    {/*<table>*/}
                    {/*    <tr>*/}
                    {/*        <th>权限名称</th>*/}
                    {/*        <th>使用场景</th>*/}
                    {/*        <th>调用路径</th>*/}
                    {/*    </tr>*/}
                    {/*    <tr>*/}
                    {/*        <td>第一行第一列</td>*/}
                    {/*        <td>第一行第二列</td>*/}
                    {/*        <td>第一行第三列</td>*/}
                    {/*    </tr>*/}
                    {/*    <tr>*/}
                    {/*        <td>第二行第一列</td>*/}
                    {/*        <td>第二行第二列</td>*/}
                    {/*        <td>第二行第三列</td>*/}
                    {/*    </tr>*/}
                    {/*</table>*/}
                    <h3>1. 适用范围</h3>
                    <p>a) 在您注册小青账帐号时，您根据小青账要求提供的个人注册信息；</p>
                    <p>您了解并同意，以下信息不适用本隐私权政策：</p>
                    <p>b) 违反法律规定或违反小青账规则行为及小青账已对您采取的措施。</p>
                    <br/>
                    <h3>2. 信息收集</h3>
                    <p>我们提供服务时，可能会收集、储存和使用下列与您有关的信息。如果您不提供相关信息，可能无法注册成为我们的用户、享受我们提供的某些服务，或者即便我们可以继续向您提供一些服务，也无法达到该服务拟达到的效果。</p>
                    <p>a) 您在注册我们的账户或使用我们的服务时，向我们提供的相关个人信息，例如电话号码、电子邮件等；您通过我们的服务向其他方提供的共享信息，以及您使用我们的服务时所储存的信息。</p>
                    <p>b) 日志信息指您使用我们服务时，系统可能会通过 cookies、web beacon
                        或其他方式自动采集的技术信息，包括：设备或软件信息，例如您的移动设备、网页浏览器或您用于接入我们的服务的其他程序所提供的配置信息、您的IP地址和您的移动设备所用的版本和设备识别码；
                        您在使用我们服务时搜索和浏览的信息，例如您使用的网页搜索词语、访问的社交媒体页面url地址，以及您在使用我们服务时浏览或要求提供的其他信息和内容详情；有关您曾使用的移动应用（APP）和其他软件的信息，以及您曾经使用该等移动应用和软件的信息；您通过我们的服务进行通讯的信息，例如曾通讯的账号，以及通讯时间、数据和时长；</p>
                    <p>c) 为了保障软件与服务的安全运行，我们会收集您的应用安装列表、设备型号、设备名称、 设备唯一标识符（包括：IMEI、Android
                        ID、IDFA、设备Mac地址）、语言设置、字体设置、操作系统和应用程序版本、登录IP地址、接入网络的方式。</p>
                    <p>e) 为了更便捷服务用户，用户同意隐私协议后，应用集成的第三方微信SDK会获取本机应用列表、设备型号、设备序列号、操作系统、设备Mac地址、唯一设备识别码，使用于支持微信登录、分享、支付服务。 也会获取软件安装列表以判断是否安装微信app，用于支持微信登录、分享、支付服务。</p>
                    <br/>
                    <p><strong>d) 第3方SDK使用情况</strong></p>
                    <p>为了保障我们客户端的稳定运行、功能实现，使您能够使用和享受更多的服务及功能，我们的应用中会嵌入授权合作伙伴的SDK或其他应用程序。此时，第三方SDK服务商可能会收集您的相关信息。</p>
                    <p>具体第三方SDK的使用情况如下：</p>
                    <p><strong>io.flutter.embedding SDK</strong></p>
                    <p>使用目的：为了使用粘贴复制功能，会发生获取剪切板行为，使用横屏竖屏功能，会发生获取传感器行为，该行为可能会在部分手机的设置中，用户无法主动关闭。</p>
                    <p>收集信息类型：获取剪切板行为、获取设备Mac地址、使用横屏竖屏功能，会发生获取传感器行为。</p>
                    <p><strong>微信 SDK</strong></p>
                    <p>使用目的：支持微信登录、分享、支付服务</p>
                    <p>收集信息类型：本机应用列表、设备型号、设备序列号、操作系统、设备Mac地址、唯一设备识别码（IMEI/android ID/IDFA/OPENUDID/GUID、SIM 卡 IMSI
                        信息）、登录IP地址、微信软件版本号、接入网络的方式、类型和状态、网络质量数据、操作日志</p>
                    <p>官网地址：<a href="https://open.weixin.qq.com/">https://open.weixin.qq.com/</a></p>
                    <p><strong>支付宝 SDK</strong></p>
                    <p>使用目的：帮助用户在应用内使用支付宝</p>
                    <p>收集信息类型：本机应用列表、设备Mac地址、唯一设备识别码（IMEI/android ID/IDFA/OPENUDID/GUID、SIM 卡 IMSI 信息）、运营商信息、网络状态信息</p>
                    <p>官方说明或隐私政策：<a href="https://opendocs.alipay.com/open/54">https://opendocs.alipay.com/open/54</a>
                    </p>
                    <h3>3. 信息使用</h3>
                    <p>a)
                        小青账不会向任何无关第三方提供、出售、出租、分享或交易您的个人信息，除非事先得到您的许可，或该第三方和小青账（含小青账关联公司）单独或共同为您提供服务，且在该服务结束后，其将被禁止访问包括其以前能够访问的所有这些资料。</p>
                    <p>b) 小青账亦不允许任何第三方以任何手段收集、编辑、出售或者无偿传播您的个人信息。任何小青账平台用户如从事上述活动，一经发现，小青账有权立即终止与该用户的服务协议。</p>
                    {/*<p>b) 为服务用户的目的，小青账可能通过使用您的个人信息，向您提供您感兴趣的信息，包括但不限于向您发出产品和服务信息，或者与小青账合作伙伴共享信息以便他们向您发送有关其产品和服务的信息（后者需要您的事先同意）。</p>*/}
                    <p>c) 您可通过如下路径注销小青账账户：</p>
                    <p> 小青账 App 操作路径：打开小青账 App，登录小青账帐户，通过“我的 → 设置 → 账户与安全 → 注销帐号”。</p>
                    <p> 当您符合约定的账户注销条件并注销某小青账账户后，您该账户内的所有信息将被清空，我们将不会再收集、使用或对外提供与该账户相关的个人信息，但您在使用小青账服务期间提供或产生的信息我们仍需按照监管要求的时间进行保存，且在该依法保存的时间内有权机关仍有权依法查询。</p>
                    <p><strong>当您决定不再使用我们的产品或服务时，可以申请注销账户。注销账户后，除法律法规另有规定外，我们将删除或匿名化处理您的个人信息。</strong></p>
                    <br/>
                    <h3>4. 信息披露</h3>
                    <p>a) 经您事先同意，向第三方披露；</p>
                    <p>b) 为提供您所要求的产品和服务，而必须和第三方分享您的个人信息；</p>
                    <p>c) 根据法律的有关规定，或者行政或司法机构的要求，向第三方或者行政、司法机构披露；</p>
                    <p>d) 如您出现违反中国有关法律、法规或者小青账服务协议或相关规则的情况，需要向第三方披露；</p>
                    <p>e) 如您是适格的知识产权投诉人并已提起投诉，应被投诉人要求，向被投诉人披露，以便双方处理可能的权利纠纷；</p>
                    <p>f) 其它小青账根据法律、法规或者网站政策认为合适的披露。</p>
                    <br/>
                    {/*<h3>5. 信息存储和交换</h3>*/}
                    {/*<p>a) 我们的产品集成蒲公英SDK，蒲公英SDK需要收集您的设备Mac地址、唯一设备识别码以提供统计分析服务。</p>*/}
                    <br/>
                    <h3>5. 信息安全</h3>
                    <p>a)
                        小青账帐号均有安全保护功能，请妥善保管您的用户名及密码信息。小青账将通过对用户密码进行加密等安全措施确保您的信息不丢失，不被滥用和变造。尽管有前述安全措施，但同时也请您注意在信息网络上不存在“完善的安全措施”。</p>
                    <br/>
                    <h3>6. 设备权限</h3>
                    <p>a)
                        在具体提供服务的过程中我们可能需要调用您的一些设备权限，例如为上传图片的目的获得您的照片访问权限；为实现拍照和上传目的获得您的相机权限；为实现保存图片目的获取您的存储权限等，我们将在具体场景下以弹窗的形式询问并征得您的同意。您可以在设备的系统设置功能中选择打开或关闭相应的权限，从而允许或拒绝我们收集和使用您的个人信息。</p>
                    <h3>7. 自启动和关联应用说明</h3>
                    <p>a) 为确保本应用处于关闭或后台运行状态下可正常接收到客户端推送的信息，本应用须使用（自启动）能力，将存在一定频率通过系统发送广播唤醒本应用自启动或关联启动行为，是因实现功能及服务所必要的。</p>
                    <p>b) 当您打开内容类推送消息，在征得您的明确同意后，会跳转打开相关内容。在末征得您同意的情況下，则不会有自启动或关联启动。</p>
                </div>
            </div>
        </DocumentTitle>
    );
}

export default Index;
