import React from 'react';
import DocumentTitle from 'react-document-title';

function ActivationCode() {
    return (
        <DocumentTitle title="小青账-激活码活动">
            <div className="home-page">
                <div className="home-page-wrapper">
                    <h1>激活码活动</h1>
                    <br/>
                    <p>更新日期：2021年08月01日</p>
                    <p>生效日期：2021年08月01日</p>
                    <br/>
                    <p>引言</p>
                    <br/>
                    <p>激活码是免费兑换小青账VIP的渠道码，类型分为1个月、3个月、6个月、12个月、永久VIP，或其他时效激活码，获取激活码后可以在我的会员页面进行激活码兑换VIP操作。</p>
                    <br/>
                    <h3>1. 适用范围</h3>
                    <p>a) 激活码适用于所有小青账用户。</p>
                    <p>b) 违反法律规定或违反小青账规则行为及小青账已对您采取的措施。非法所得激活码，小青账有权进行回收操作。</p>
                    <br/>
                    <h3>2. 获取方式 (多个活动可同时参与)</h3>
                    <p>a) 内部限定：关注公众号，定期有不定时活动公布， 获取活动方式（备注参与小青账激活码活动）（不受理注册时间短于5天用户，特殊情况除外）。</p>
                    <img width="200px" alt="" src="Wechat-gzh.jpeg"/>
                    <p>b) 版本狂欢：微信公众号 <strong>小青账</strong> 在后续每次版本更新都会在文章内部公布一些免费会员。</p>
                    <p>c) 定期公布其他获取方式</p>
                    <img width="200px" alt="" src="Wechat-gzh.jpeg"/>
                </div>
            </div>
        </DocumentTitle>
    );
}

export default ActivationCode;
