import React from 'react';
import {Col, Row} from 'antd';

function Footer() {
    return (
        <footer id="footer" className="dark">
            <div className="footer-wrap">
                <Row>
                    {/*<Col lg={6} sm={24} xs={24}>*/}
                    {/*  <div className="footer-center">*/}
                    {/*    <h2>Ant Design</h2>*/}
                    {/*    <div>*/}
                    {/*      <a target="_blank " href="https://github.com/ant-design/ant-design-pro">*/}
                    {/*        Ant Design Pro GitHub*/}
                    {/*      </a>*/}
                    {/*    </div>*/}
                    {/*    <div>*/}
                    {/*      <a target="_blank " href="http://ant.design">*/}
                    {/*        Ant Design*/}
                    {/*      </a>*/}
                    {/*    </div>*/}
                    {/*    <div>*/}
                    {/*      <a href="http://mobile.ant.design">Ant Design Mobile</a>*/}
                    {/*    </div>*/}
                    {/*    <div>*/}
                    {/*      <a href="http://ng.ant.design">NG-ZORRO</a>*/}
                    {/*      <span> - </span>*/}
                    {/*      Ant Design of Angular*/}
                    {/*    </div>*/}
                    {/*    <div>*/}
                    {/*      <a target="_blank " href="https://github.com/websemantics/awesome-ant-design">*/}
                    {/*        Awesome Ant Design*/}
                    {/*      </a>*/}
                    {/*    </div>*/}
                    {/*  </div>*/}
                    {/*</Col>*/}
                    {/*<Col lg={6} sm={24} xs={24}>*/}
                    {/*  <div className="footer-center">*/}
                    {/*    <h2>Ant Design</h2>*/}
                    {/*    <div>*/}
                    {/*      <a href="http://scaffold.ant.design">Scaffolds</a>*/}
                    {/*      <span> - </span>*/}
                    {/*      <span>脚手架市场</span>*/}
                    {/*    </div>*/}
                    {/*    <div>*/}
                    {/*      <a target="_blank" rel="noopener" href="http://motion.ant.design">Ant Motion</a>*/}
                    {/*      <span> - </span>*/}
                    {/*      <span>设计动效</span>*/}
                    {/*    </div>*/}
                    {/*    <div>*/}
                    {/*      <a target="_blank" rel="noopener" href="http://library.ant.design/">Axure Library</a>*/}
                    {/*      <span> - </span>*/}
                    {/*      <span>Axure 部件库</span>*/}
                    {/*    </div>*/}
                    {/*    <div>*/}
                    {/*      <a target="_blank" rel="noopener" href="http://ux.ant.design">Ant UX</a>*/}
                    {/*      <span> - </span>*/}
                    {/*      <span>页面逻辑素材</span>*/}
                    {/*    </div>*/}
                    {/*    <div>*/}
                    {/*      <a target="_blank" rel="noopener" href="https://github.com/dvajs/dva">dva </a>*/}
                    {/*      <span> - </span>*/}
                    {/*      <span> 应用框架</span>*/}
                    {/*    </div>*/}
                    {/*    <div>*/}
                    {/*      <a target="_blank" rel="noopener" href="https://github.com/dvajs/dva-cli">dva-cli </a>*/}
                    {/*      <span> - </span>*/}
                    {/*      <span> 开发工具</span>*/}
                    {/*    </div>*/}
                    {/*  </div>*/}
                    {/*</Col>*/}
                    <Col lg={6} sm={24} xs={24}>
                        <div className="footer-center">
                            <h2>关于</h2>
                            <div>
                                <a href="/rich_privacy">
                                    隐私协议
                                </a>
                            </div>
                            <div>
                                <a href="/rich_vip_agreement">
                                    会员服务协议
                                </a>
                            </div>
                            <div>
                                <a href="https://docs.bill.youqian.pro/">
                                    常见问题
                                </a>
                            </div>
                            {/*<div>*/}
                            {/*  <a target="_blank" rel="noopener" href="https://github.com/ant-design/ant-design-pro/issues">*/}
                            {/*    讨论列表*/}
                            {/*  </a>*/}
                            {/*</div>*/}
                            {/*<div>*/}
                            {/*  <a*/}
                            {/*    target="_blank"*/}
                            {/*    rel="noopener"*/}
                            {/*    href="http://ant.design/docs/resource/work-with-us"*/}
                            {/*  >*/}
                            {/*    加入我们*/}
                            {/*  </a>*/}
                            {/*</div>*/}
                        </div>
                    </Col>
                    <Col lg={6} sm={24} xs={24}>
                        <div className="footer-center">
                            <h2>
                                更多产品
                            </h2>
                            <div>
                                <a target="_blank" rel="noopener noreferrer" href="/">小青账</a>
                                <span> - </span>
                                <span>简单易用的记账app</span>
                            </div>
                            {/*<div>*/}
                            {/*    <a target="_blank" rel="noopener noreferrer"*/}
                            {/*       href="http://index.purgeteam.com/">养鸡助手</a>*/}
                            {/*    <span> - </span>*/}
                            {/*    <span>更好的管理自己的基金</span>*/}
                            {/*</div>*/}
                            {/*<div>*/}
                            {/*  <a target="_blank" rel="noopener" href="https://eggjs.org/">Egg</a>*/}
                            {/*  <span> - </span>*/}
                            {/*  <span>企业级 Node Web 开发框架</span>*/}
                            {/*</div>*/}
                        </div>
                    </Col>
                    <Col lg={6} sm={24} xs={24}>
                        <div className="footer-center">
                            <h2>
                                联系我们
                            </h2>
                            <div>
                                <a target="_blank" rel="noopener noreferrer"
                                   href="https://support.qq.com/embed/phone/487937">用户反馈</a>
                                <span> - </span>
                                <span>协助你解决问题</span>
                            </div>
                            <div>
                                <a target="_blank" rel="noopener noreferrer"
                                   href="https://www.weibo.com/u/7734729848">微博</a>
                                <span> - </span>
                                <span>小青账</span>
                            </div>
                            <div>
                                <a target="_blank" rel="noopener noreferrer"
                                   href="https://docs.bill.youqian.pro/customer_service/customer-service">公众号</a>
                                <span> - </span>
                                <span>小青账</span>
                            </div>
                            <div>
                                <a target="_blank" rel="noopener noreferrer" href="/business">商务合作</a>
                                <span> - </span>
                                <span>一起来探索吧</span>
                            </div>
                        </div>
                    </Col>
                    <Col lg={6} sm={24} xs={24}>
                        <div className="footer-center">
                            <h2>
                                小青账
                            </h2>
                            <img width="100px" alt="" src="/wechat-gzh1.png"/>
                        </div>
                    </Col>
                </Row>
            </div>
            <Row className="bottom-bar">
                <Col lg={6} sm={24}>
                    <div style={{textAlign: "left"}}>
                        <span>天天坚持自律给我自由，自律及财务自由</span>
                    </div>
                    {/*<div className="translate-button">*/}
                    {/*  <Button ghost size="small">*/}
                    {/*    English*/}
                    {/*  </Button>*/}
                    {/*</div>*/}
                </Col>
                <Col lg={18} sm={24}>
                    <span style={{marginRight: 12}}><a href="https://beian.miit.gov.cn/"
                                                       target="view_window">豫ICP备2021016109号-1</a></span>
                    <span style={{marginRight: 12}}><a href="https://beian.miit.gov.cn/"
                                                       target="view_window">豫B2-20220734</a></span>
                    <span style={{marginRight: 12}}>Copyright © 有钳智能科技（郑州）有限公司</span>
                </Col>
            </Row>
        </footer>
    );
}

export default Footer;
