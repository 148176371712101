import React from 'react';
import DocumentTitle from 'react-document-title';
import {Typography} from 'antd';
const { Paragraph } = Typography;

function Business() {
    return (
        <DocumentTitle title="记一笔-隐私政策">
            <div className="home-page">
                <div className="home-page-wrapper">
                    <h1>商务合作</h1>
                    <br/>
                    <p>嗨，欢迎您和我们一起来探索未来的商业模式，让我们一起成长。</p>
                    <br/>
                    <h3>合作方式</h3>
                    <p>如您有意向可联系 下方邮件</p>
                    <p>还请描述 <strong>对接公司</strong>、<strong>合作类型</strong>、<strong>合作方式</strong> 等</p>
                    <p>商务对接</p>
                    <p><strong><Paragraph
                        copyable={{text: 'business@youqian.pro'}}>business@youqian.pro</Paragraph></strong></p>
                    <p><strong>PS:谢绝三方广告，如开屏广告等</strong></p>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    <p></p>
                </div>
            </div>
        </DocumentTitle>
    );
}

export default Business;
