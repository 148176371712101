import React, {useState, useEffect} from 'react';
import DocumentTitle from 'react-document-title';
import {DownloadOutlined} from '@ant-design/icons';
import {Button, message} from 'antd';

export default () => {
    const [messageApi, contextHolder] = message.useMessage();
    const [state, setState] = useState("none");
    const [url, setUrl] = useState();
    const [size, setSize] = useState();
    const [version, setVersion] = useState();
    const [time, setTime] = useState();

    useEffect(() => {
        officialVersion();
    }, []);

    const officialVersion = () => {
        // https://bill.api.youqian.pro
        fetch("https://bill.api.youqian.pro/version-control-server/user/version-control/officialVersion", {
            method: 'GET',
            // body: formData,
            // headers: {}
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.succ) {
                    setState("block");
                    setUrl(response.data.url);
                    setSize(response.data.size + "MB")
                    setVersion(response.data.version_);
                    setTime(response.data.createTime);
                }
            })
            .catch(error => console.error('Error:', error));
    }
    // 下载apk
    const download = (url) => {
        if (state !== "block") {
            messageApi.info('资源飞走了～');
            return;
        }
        const eleLink = document.createElement('a');
        eleLink.style.display = 'none';
        eleLink.download = "小青账尝鲜版.apk"
        // eleLink.target = "_blank"
        eleLink.href = url;
        // eleLink.href = record;
        document.body.appendChild(eleLink);
        eleLink.click();
        document.body.removeChild(eleLink);

        // fetch(url)
        //     .then((res) => res.blob())
        //     .then((blob) => {
        //         // 将链接地址字符内容转变成blob地址
        //         eleLink.style.display = 'none';
        //         eleLink.href = URL.createObjectURL(blob);
        //         // @ts-ignore
        //         eleLink.download = "小青账尝鲜版.apk"; // 下载文件的名字
        //         // a.download = url.split('/')[url.split('/').length -1] //  // 下载文件的名字
        //         document.body.appendChild(eleLink);
        //         eleLink.click();
        //         document.body.removeChild(eleLink);
        //     })
    }
    return (
        <DocumentTitle title="安卓尝鲜版">
            <div style={{marginBottom: 105}}>
                <div className="home-page" style={{textAlign: "center"}}>
                    <div className="home-page-wrapper">
                        <h1>感谢支持小青账</h1>
                        <br/>
                        <p>尝鲜版抢先使用新版本系统，感受新功能变化，优先下载新版本体验，直接参与软件升级。</p>
                        <p>小青账进步的每一步，都有你的参与，再次感谢支持小青账～</p>
                        <img width="210px" alt="" src="Wechat-gzh.jpeg"/>
                        <br/>
                    </div>
                    <div>
                        {contextHolder}
                        <Button style={{
                            minWidth: 110,
                            height: 40,
                            borderRadius: 20,
                            fontSize: 16,
                        }} type="primary" shape="round" icon={<DownloadOutlined/>}
                                onClick={() => {
                                    download(url)
                                }}
                                ghost>
                            安卓尝鲜版
                        </Button>
                        <br/>
                        <br/>
                        <div style={{display: state}}>
                            <p><span>应用大小</span>：<span>{size}</span></p>
                            <p><span>版本</span>：<span>{version}</span></p>
                            <p><span>更新时间</span>：<span>{time}</span></p>
                        </div>
                    </div>
                </div>
            </div>
        </DocumentTitle>
    );

};
