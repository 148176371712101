import React from 'react';
import DocumentTitle from 'react-document-title';

function AppHomeActivationCode1() {
    return (
        <DocumentTitle title="小青账-激活码活动">
            <div className="home-page">
                <div className="home-page-wrapper">
                    <h1>新logo建议征集活动</h1>
                    <br/>
                    <p>更新日期：2023年09月29日</p>
                    <p>生效日期：2023年09月29日</p>
                    <br/>
                    <p>引言</p>
                    <br/>
                    <p>新logo有人爱有人厌，为了征集更多的建议，大家可参加本次活动，参与活动凡合格者都将送出礼品。（实物礼品登记后30工作日内发出，虚拟物品登记后10个工作日内发出）</p>
                    <br/>
                    <h3>1. 适用范围【参与资格、获奖资格的取消】</h3>
                    <p>a) <strong>小青账用户注册时间大于14天以上。</strong></p>
                    <p>b) 此活动每人仅可参与一次，同一小青账账号/身份证/手机号/手机设备/微信号均视为同一用户。</p>
                    <p>c) 您不得以不正当手段或舞弊行为参与本活动，包括但不限于：开设多个小青账账号重复参与本活动，或通过机器人软件、蜘蛛软件、爬虫软件、刷屏软件等任何程序或技术手段（包括但不限于盗用他人账号等）参与本活动</p>
                    <p>d) 如我们发现或接举报后发现您存在以上任意一种情况，一经查实，我们有权采取包括但不限于取消您的参与资格、取消奖励、追回已发放奖励等措施。</p>
                    <p>e) 本次活动解释权归小青账所有。</p>
                    <br/>
                    <h3>2. 活动奖品</h3>
                    <p>a) 本次实物奖品限量1000份，先到先得。</p>
                    <p>b) 虚拟奖品限量3000份，先到先得。</p>
                    <h3>3. 参与方式</h3>
                    <p>请查联系下方活动人员获取近期活动。</p>
                    <img width="200px" alt="" src="运行小哥2.png"/>
                </div>
            </div>
        </DocumentTitle>
    );
}

export default AppHomeActivationCode1;
