import React from 'react';
import DocumentTitle from 'react-document-title';

function Index() {
    return (
        <DocumentTitle title="小青账-帮助">
            <div className="home-page">
                <div className="home-page-wrapper">
                    <h1>导入帮助</h1>
                    <br/>
                    <p>引言</p>
                    <br/>
                    <p>通过表格方式导入其他App账单、如支付宝、微信</p>
                    <p>如遇到现有支持的来源 导入出问题请联系 公众号（小青账）</p>
                    <p>其他app导入需要程序员小哥哥开发支持，请在 公众号（小青账）留言备注需要支持的App。</p>
                    <br/>
                    <br/>
                    <h3>1.
                        支付宝 点这里<span role='img' aria-labelledby="panda1">👈</span></h3>
                    <p>支持手机、电脑导出的账单csv格式文件导入</p>
                    <h4><a
                        href="https://note.youdao.com/ynoteshare1/mobile.html?id=e45e053b303a1b8941d1e9144b037508&type=note">手机客户端获取方法</a>
                    </h4>
                    <h4><a
                        href="https://note.youdao.com/ynoteshare1/mobile.html?id=de8a72ecd449a4942516afca001ba5b0&type=note">电脑网页获取方法</a>
                    </h4>
                    <h4><a
                        href="https://note.youdao.com/ynoteshare1/mobile.html?id=403c6b325f7375e40c6e5d16280f1ad5&type=note">保存到手机导入账单</a>
                    </h4>
                    <br/>
                    <h3>2.微信 </h3>
                    <p>支持手机导出的csv格式文件导入</p>
                    <h4><a
                        href="https://note.youdao.com/ynoteshare1/mobile.html?id=75e063683ee78a8f660d0f104e583aa0&type=note">获取微信账单</a>
                    </h4>
                    <br/>
                    <h3>3.网易有钱</h3>
                    <p>支持网易有钱xlsx格式文件导入。</p>
                    <br/>
                </div>
            </div>
        </DocumentTitle>
    );
}

export default Index;
